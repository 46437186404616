/* You can add global styles to this file, and also import other style files */

body {
  color: #565656;
  background-color: #f5f5f5;
}

.page-container {
  flex: 1;
  margin-bottom: 25px;
  padding: 35px;
  background-color: #fff;
}
.mouse-help {
  cursor: help;
}
.buttonNewline {
  .ant-popover {
    white-space: nowrap !important;
  }
}
.previewPopover {
  .ant-popover-title {
    min-width: 164px;
  }
}
.translate_round {
  font-weight: 900;
}

.cdk-drag-preview {
  position: relative;
  z-index: 100;
  display: block;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 3px 2px rgba(0, 0, 0, 0.14);
  cursor: pointer;
  transition: all 0.05s, visibility 0s;
  .edit-delete {
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 10;
    cursor: pointer;
  }
  .ant-collapse-header {
    position: relative;
    display: flex;
    align-items: center;
    padding: 12px 16px;
    color: rgba(0, 0, 0, 0.65);
    line-height: 1.5715;
    cursor: pointer;
    transition: all 0.2s, visibility 0s;
    &::before {
      display: table;
      content: '';
    }
    .ant-collapse-arrow {
      display: inline-block;
      margin-right: 12px;
      font-size: 12px;
      vertical-align: -1px;
    }
    .ant-collapse-extra {
      margin-left: auto;
    }
  }
}
.cdk-drag-placeholder {
  opacity: 0;
}
.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
.drop-list.cdk-drop-list-dragging .drop-item:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
.item-drop-icon {
  display: block;
  cursor: move;
}
