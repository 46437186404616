// You can directly set the default theme
// - `default` Default theme
// - `dark` Import the official dark less style file
// - `compact` Import the official compact less style file
@import '~@delon/theme/theme-default.less';

// ==========The following is the custom theme variable area==========
// The theme paraments can be generated at https://ng-alain.github.io/ng-alain/
@primary-color: #0075ff; // 侯斯特默认主题色
@enable-all-colors: true;

@layout-header-background: white;
@layout-body-background: #ebf4ff;
@layout-header-padding: 0;

// se表单组件的label靠左显示
.se__label {
  justify-content: flex-start !important;
}
